import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=3618580b&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseModal: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/base/Modal.vue').default})
